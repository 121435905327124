<template>
  <div :class="['header-balance', rootClasses]">
    <NuxtImg v-if="variant.type === EHeaderBalanceTypes.COINS" :src="variant.icon" class="header-balance__icon" />
    <SvgoHeaderBalanceSkins v-else-if="variant.type === EHeaderBalanceTypes.SKINS" class="header-balance__icon" />
    <SvgoWalletIcon
      v-else-if="variant.type === EHeaderBalanceTypes.MONEY && variant.showIcon"
      class="header-balance__icon"
    />
    <span class="header-balance__text">{{ shortFall }}</span>
    <UserController v-if="currencyIcon">
      <template #default="{ isFake, user }">
        <SharedPrettyCurrency :is-fake="isFake" :currency="user?.finance?.currency" class="header-balance__text">
          <template #currency="{ currency }">
            <span class="header-balance__text">{{ currency }}</span>
          </template>
        </SharedPrettyCurrency>
      </template>
    </UserController>
  </div>
</template>

<script setup lang="ts">
import { EHeaderBalanceTypes, type IHeaderBalanceProps } from './HeaderBalance.types';

const props = defineProps<IHeaderBalanceProps>();
const { variant } = toRefs(props);

const rootClasses = computed(() => ({
  [`header-balance--${variant.value.type}`]: true,
}));

const currencyIcon = computed(() => {
  if (variant.value.type !== EHeaderBalanceTypes.COINS) return useFormatCurrency(shortFall.value).slice(-2);
  return '';
});

const eventColor = computed(() => {
  if (variant.value.type === EHeaderBalanceTypes.COINS) return variant.value.color;
  return '';
});

const shortFall = computed<string>(() => {
  if (variant.value.type === EHeaderBalanceTypes.COINS) {
    return String(parseInt(String(props.amount), 10));
  }
  return GlobalUtils.Prices.toFixedDefault(props.amount);
});
</script>

<style lang="scss">
.header-balance {
  --event-color: v-bind('eventColor');
}

.event-color {
  color: var(--event-color);
}
</style>

<style scoped lang="scss" src="./HeaderBalance.scss" />
